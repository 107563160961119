<template>
    <div class="member-join-box">
        <div class="container">
            <!-- 文字 -->
            <div class="member-join-text">
                <div class="member-join-text-title">{{joinTitle}}</div>
                <div class="member-join-text-subtitle">{{joinSubtitle}}</div>
            </div>
            <!-- 图片 -->
            <div class="member-join-pic">
                <div v-for="(item, index) in joinPic"
                    :key="index">
                    <img :src="require('@/assets/'+item)" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        joinTitle: String,
        joinSubtitle: String,
        joinPic: Array
    }
}
</script>

<style scoped>
.member-join-box{
    padding-top: 50px;
    background-color: #fff;
}
/* 文字 */
.member-join-text{
    margin-bottom: 50px;
    text-align: center;
}
.member-join-text-title{
    letter-spacing: 4px;
    font-size: 24px;
    font-weight: bold;
    color: #8d9afc;
    margin-bottom: 10px;
}
.member-join-text-subtitle{
    font-weight: bold;
    color: #909399;
}
/* 图片 */
.member-join-pic{
    display: flex;
    justify-content: center;
}
.member-join-pic>div{
    padding: 10px;
}
</style>